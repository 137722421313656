import { h } from 'preact'
import PropTypes from 'prop-types'

import SwitchListWithDescription from 'components/SwitchListWithDescription'
import CategorizedConsentsList from 'components/CategorizedConsentsList'

import {
  getBobConsentDescriptionsWithOrgName,
  getCCPAConsents,
  getConsentsByCategory,
} from 'lib/accountDataSpec'

export default function BobConsentsForm({className = '', type, ...props}){
  const { organization } = props
  props.className = `BobConsentsForm ${className}`
  props.descriptions = getBobConsentDescriptionsWithOrgName({
    organizationName: organization.name,
  })
  props.onSwitchChange = consents => {
    const formattedConsents = {}
    Object.entries(consents).forEach(([key, enabled]) => {
      formattedConsents[key] = { enabled }
    })
    props.onChange(formattedConsents)
  }

  if (type === 'GDPR') return <GDPRConsents {...props} />
  if (type === 'CCPA') return <CCPAConsents {...props} />
}

BobConsentsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  organization: PropTypes.object,
  disabled: PropTypes.bool,
  descriptionsDefaultOpen: PropTypes.bool,
  type: PropTypes.oneOf([
    'GDPR',
    'CCPA',
  ]).isRequired,
  className: PropTypes.string,
}

function CCPAConsents({
  onSwitchChange,
  organization,
  disabled,
  descriptionsDefaultOpen,
  descriptions,
}) {
  const consents = getCCPAConsents({})
  const switchesAndText = consents.map(consent => ({
    title: consent,
    description: descriptions[consent],
    checked: !!organization.consents[consent],
    unsaved: false,
  }))
  return <SwitchListWithDescription
    className="BobConsentsForm BobCCPAConsents"
    onSwitchChange={onSwitchChange}
    switchesAndText={switchesAndText}
    disabled={disabled}
    descriptionsDefaultOpen={descriptionsDefaultOpen}
  />
}

function GDPRConsents({
  onSwitchChange,
  organization,
  disabled,
  descriptionsDefaultOpen,
  descriptions,
}) {
  return <CategorizedConsentsList
    className="BobConsentsForm BobGDPRConsents"
    onChange={onSwitchChange}
    disabled={disabled}
    enabledConsents={organization.consents}
    consentsByCategory={getConsentsByCategory({})}
    descriptions={descriptions}
    descriptionsDefaultOpen={descriptionsDefaultOpen}
  />
}
BobConsentsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  organization: PropTypes.object,
  disabled: PropTypes.bool,
  descriptionsDefaultOpen: PropTypes.bool,
  type: PropTypes.oneOf([
    'GDPR',
    'CCPA',
  ]).isRequired,
  className: PropTypes.string,  // Add this line
}
