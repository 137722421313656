import JLINCAPI from 'lib/JLINCAPI'

class BobAPI extends JLINCAPI {

  sendHelpRequest(helpRequest){
    return this.postJSON('/help_request', helpRequest)
  }

  signup({email, stripeToken, inviteToken}){
    return this.postJSON('/signup', {
      email,
      stripeToken,
      inviteToken,
    })
  }

  verifySignup({signupToken}) {
    return this.postJSON('/verify_signup', { signupToken })
  }

  completeSignup({
    signupToken,
    name,
    password,
    passwordConfirmation,
    keepMeLoggedIn,
  }){
    return this.postJSON('/complete_signup', {
      signupToken,
      name,
      password,
      passwordConfirmation,
      keepMeLoggedIn,
    }).then(({ email, sessionId, organization, invitedToNetwork }) => {
      this.sessionId = sessionId
      return { email, sessionId, organization, invitedToNetwork }
    })
  }

  login({ email, password, keepMeLoggedIn, organizationAdminInviteToken }){
    return this.postJSON('/login', { email, password, keepMeLoggedIn, organizationAdminInviteToken })
      .then(({ sessionId, invitedToNetwork }) => {
        this.sessionId = sessionId
        return { email, sessionId, invitedToNetwork }
      })
  }

  logout(){
    this.postJSON('/logout', {})
    this.sessionId = null
  }

  verifySession(){
    return this.postJSON('/verify_session')
  }

  loadOverview(){
    return this.getJSON('/overview')
  }

  loadOrganizationProfile(){
    return this.getJSON('/orgs/profile')
  }

  updateOrganizationProfile({ changes }){
    return this.postJSON('/orgs/profile', {organizationProfile: changes})
  }

  inviteEndUser({endUserPersonalData}){
    return this.postJSON('/orgs/invite_end_user', {endUserPersonalData}).then(({signup}) => signup)
  }

  createOrganizationInvite({ invite_to_network }){
    return this.postJSON('/organization_invites', { invite_to_network })
  }

  getOrganizationInvite({ inviteToken }){
    return this.getJSON(`/organization_invites/${inviteToken}`)
  }

  resolveNetworkInvite({ organizationApikey, accept }){
    return this.postJSON(`/networks/${organizationApikey}/invite/${accept ? 'accept' : 'reject'}`)
  }

  removeOrganizationFromNetwork({ memberOrganizationApikey }){
    return this.postJSON(`/networks/members/${memberOrganizationApikey}/remove`)
  }

  leaveNetwork({ networkOrganizationApikey }){
    return this.postJSON(`/networks/${networkOrganizationApikey}/leave`)
  }

  inviteOrganizationToNetwork({ inviteeOrganizationApikey }){
    return this.postJSON(`/networks/invites/${inviteeOrganizationApikey}`)
  }

  getNetworkInvites() {
    return this.getJSON('/networks/invites')
  }

  loadConsentReport(){
    return this.getJSON('/orgs/consent_report')
  }

  requestPasswordReset({ email }){
    return this.postJSON(`/reset_password/request`, { email })
  }

  verifyResetPasswordToken({ resetPasswordToken }){
    return this.getJSON(`/reset_password/verify/${resetPasswordToken}`)
      .then(({ email }) => email)
  }

  resetPassword({ resetPasswordToken, password, passwordConfirmation }){
    return this.postJSON(`/reset_password`, { resetPasswordToken, password, passwordConfirmation })
  }

  getAssetUploadToken(){
    return this.postJSON(`/create_asset_upload_token`)
      .then(({ assetUploadToken }) => assetUploadToken)
  }

  getEndUserInvites({ page }){
    return this.getJSON(`/end_user_invites`, { page })
  }

  deleteEndUserInvite({ inviteToken }){
    return this.postJSON(`/delete_end_user_invite`, { inviteToken })
  }

  loadEndUsers({ page, filters }){
    filters = filters ? JSON.stringify(filters) : undefined
    return this.getJSON(`/end_users`, { page, filters })
  }

  getBuyingInterests({ type }){
    return this.getJSON(`/orgs/buying_interests/${type}`)
  }

  createBuyingInterest({ buyingInterest }){
    return this.postJSON(`/orgs/buying_interests`, { buyingInterest })
  }

  deleteBuyingInterest({ uid }){
    return this.postJSON(`/orgs/buying_interests/${uid}/delete`)
  }

  getFeedPosts({ before, type, feedOwnerApikey, networkOrganizationApikey }){
    if (before instanceof Date) before = before.toISOString()
    return this.getJSON(`/feed/posts`, { before, type, feedOwnerApikey, networkOrganizationApikey })
  }

  hideFeedPost({ feedPostUid }){
    return this.postJSON(`/feed/posts/hide`, { feedPostUid })
  }

  showFeedPost({ feedPostUid }){
    return this.postJSON(`/feed/posts/show`, { feedPostUid })
  }

  createFeedPost({ post, type, feedOwnerApikey }){
    return this.postJSON(`/feed/posts`, { post, type, feedOwnerApikey })
  }

  deleteFeedPost({ feedPostUid, comment, reason, sendNotification }){
    return this.postJSON(`/feed/posts/${feedPostUid}/delete`, {
      feedPostUid,
      comment,
      reason,
      sendNotification
    })
  }

  updateOrganizationFeedPost({ feedPostUid, post }){
    return this.postJSON(`/feed/posts/${feedPostUid}`, { post })
  }

  getEmails() {
    return this.getJSON(`/orgs/emails`)
  }

  addEmail({ email }) {
    return this.postJSON(`/orgs/emails/add`, { email })
  }

  verifyEmail({ verificationCode }) {
    return this.postJSON(`/orgs/emails/verify`, { verificationCode })
  }

  getAllOrganizations(){
    return this.getJSON('/organizations')
      .then(({ organizations }) => organizations)
  }

  getOrganization({ organizationApikey }){
    return this.getJSON(`/organizations/${organizationApikey}`)
  }

  getBisas(){
    return this.getJSON('/bisas')
  }

  getBisaOfferings(){
    return this.getJSON('/bisa_offerings')
  }

  offerBisa({ targetOrganizationApikey }){
    return this.postJSON('/bisa_offerings', { targetOrganizationApikey })
  }

  resolveBisaOffering({ bisaOfferingId, accept }){
    return this.postJSON(`/bisa_offerings/${bisaOfferingId}/${accept ? 'accept' : 'reject'}`)
  }

  createBisaEvent({ bisaEvent }){
    return this.postJSON('/bisa_events', bisaEvent)
  }

  getCustomPersonalDataFields(){
    return this.getJSON('/custom_personal_data_fields')
  }
}

export default new BobAPI({ urlPrefix: JLINC_B_API })
